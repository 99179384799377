import {
  Button,
  Form,
  Input,
  Radio,
  Select,
  Col,
  Row,
  notification,
  Checkbox,
} from "antd";
const { TextArea } = Input;
import { useForm } from "antd/lib/form/Form";
import { setModal } from "features/modalSlice";
import { useDispatch } from "react-redux";
import { PayloadType, Role, SelectOptionType, UserType } from "type";
import { closeModal, getYupSync } from "utils";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { getRoles } from "features/roleSlice";
import _, { isUndefined } from "lodash";
import { getUsers } from "features/userSlice";
import queryString from "query-string";
import { useSelector } from "react-redux";
import { getSharedDatas } from "features/sharedDataSlice";
import { LOAI_CHUONG } from "const";
import {
  createSubscriber,
  getSubscribers,
  updateSubscriber,
} from "features/subscriberSlice";

import { getTypeGroups } from "features/typeGroupSlice";
import { getHerdTypes } from "features/herdTypeSlice";
import dataProvince from "dataAddress/province.json";
import dataDistrict from "dataAddress/districts.json";
import { getServicePackages } from "features/servicePackageSlice";

function CUUser({ idUpdate, record }: any) {
  const [form] = useForm();
  const dispatch = useDispatch();
  const { query } = useSelector((state: any) => state.subscriberReducer);
  const [loading, setLoading] = useState(false);
  const { open } = useSelector((state: any) => state.modalReducer);
  const [listAdmin, setListAdmin] = useState<SelectOptionType[]>([]);
  const [listFarmType, setListFarmType] = useState<SelectOptionType[]>([]);
  const [listFarmScale, setListFarmScale] = useState<SelectOptionType[]>([]);
  const [districtList, setDistrictList] = useState<SelectOptionType[]>([]);
  const [servicePack, setServicePack] = useState<SelectOptionType[]>([]);

  // Danh sách  tỉnh
  const optionProinces = dataProvince.map((item: any) => {
    return {
      label: item.province_name,
      value: item.province_id,
    };
  });
  //Lấy danh sách tài khoản
  function handleGetUser() {
    const payload: PayloadType = {
      query: queryString.stringify(
        {
          ...query,
          populate: "personInChargeId, personalConfig. farmScaleId  farmTypeId",
        },
        {
          skipNull: true,
          skipEmptyString: true,
        }
      ),
      skipUpdateReducer: false,
      callback: {
        success() {},
        failed(errorMessage) {
          notification.error({
            message: "Có lỗi xảy ra trong quá trình lấy danh sách! ",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getSubscribers(payload));
  }

  // lấy danh sách  người phụ trách
  const getFieldListAdmin = () => {
    const payload: PayloadType = {
      query: queryString.stringify({
        page: 1,
        limit: 999,
        status: "active",
      }),
      skipUpdateReducer: true,
      callback: {
        success(typeGroups) {
          const results = _.get(typeGroups, "results", []);
          if (!!results && Array.isArray(results)) {
            setListAdmin(
              results.map((item: any) => {
                return {
                  label: item.name,
                  value: item.id,
                };
              })
            );
          }
        },
        failed(errorMessage) {
          notification.error({
            message: "Có lỗi xảy ra trong quá trình lấy danh sách admin! ",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getUsers(payload));
  };
  useEffect(() => {
    getFieldListAdmin();
  }, []);

  // lấy danh sách quy mô trang trại
  const getFieldFarmScale = () => {
    const payload: PayloadType = {
      query: queryString.stringify({
        page: 1,
        limit: 999,
        code: "FARM_SCALE",
      }),
      skipUpdateReducer: true,
      callback: {
        success(typeGroups) {
          const results = _.get(typeGroups, "results", []);
          const typeGroupId = _.get(results, "[0].id", "");
          const payload: PayloadType = {
            query: queryString.stringify({
              page: 1,
              limit: 999,
              typeGroupId: typeGroupId,
            }),
            skipUpdateReducer: true,
            callback: {
              success(listScales) {
                const results = _.get(listScales, "results", []);
                if (!!results && Array.isArray(results)) {
                  setListFarmScale(
                    results.map((item: any) => {
                      return {
                        label: item.name,
                        value: item.id,
                      };
                    })
                  );
                }
              },
              failed(errorMessage) {},
            },
          };
          dispatch(getSharedDatas(payload));
        },
        failed(errorMessage) {},
      },
    };
    dispatch(getTypeGroups(payload));
  };

  useEffect(() => {
    getFieldFarmScale();
  }, []);

  // lấy phân loại trang trại

  const getHerdType = () => {
    const payload: PayloadType = {
      query: queryString.stringify({
        page: 1,
        limit: 999,
      }),
      skipUpdateReducer: true,
      callback: {
        success(listHerdTypes) {
          const results = _.get(listHerdTypes, "results", []);
          if (!!results && Array.isArray(results)) {
            setListFarmType(
              results
                .map((item: any) => {
                  return {
                    label: item.name,
                    value: item.id,
                    code: item.code,
                  };
                })
                .filter((item) => item.code !== "TTTH")
            );
          }
        },
        failed(errorMessage) {
          notification.error({
            message: "Có lỗi xảy ra trong quá trình lấy danh sách admin! ",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getHerdTypes(payload));
  };
  useEffect(() => {
    getHerdType();
  }, []);

  // lấy phân loại trang trại

  const getServicePacks = () => {
    const payload: any = {
      query: queryString.stringify({
        page: 1,
        limit: 999,
      }),
      skipUpdateReducer: true,
      callback: {
        success(listHerdTypes: any) {
          const results = _.get(listHerdTypes, "results", []);
          if (!!results && Array.isArray(results)) {
            setServicePack(
              results.map((item: any) => {
                return {
                  label: item.name,
                  value: item.id,
                };
              })
            );
          }
        },
        failed(errorMessage: any) {
          notification.error({
            message:
              "Có lỗi xảy ra trong quá trình lấy danh sách gói dịch vụ! ",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getServicePackages(payload));
  };
  useEffect(() => {
    getServicePacks();
  }, []);

  const handleChangeProvince = (value: any) => {
    const dataDistrictByProvince = dataDistrict.filter((item: any) => {
      return item.province_id === value;
    });
    const optionDistrict = dataDistrictByProvince.map((data) => {
      return {
        label: data.district_name,
        value: data.district_name,
      };
    });
    setDistrictList(optionDistrict);
  };

  const handleConvertProvinceIdToName = (provinceId: any) => {
    return dataProvince.find((item: any) => item.province_id === provinceId);
  };

  const formSchema = yup.object().shape({
    name: yup.string().required("Tên chủ trại không được để trống!"),
    phone: yup
      .string()
      .length(10, "Số điện thoại bao gồm 10 số!")
      .required("Tên đăng nhập không được để trống!")
      .matches(
        /0[1-9][0-9]{8}/g,
        "Vui lòng nhập đúng định dạng số điện thoại!"
      ),
    email: yup.string().email("Không đúng định dạng email!"),
    idUpdate: yup.string().nullable(), // Định nghĩa trường 'a' là một string hoặc undefined
    password: yup.string().test(
      "conditional-password",
      "Mật khẩu  chứa ít nhất 8 kí tự, bao gồm chữ và số!",

      function (value) {
        if (_.isUndefined(idUpdate)) {
          // Nếu 'a' tồn tại, kiểm tra mật khẩu theo yêu cầu
          return yup
            .string()
            .required("Vui lòng nhập mật khẩu!")
            .matches(
              /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/,
              "Mật khẩu chứa ít nhất 8 kí tự, bao gồm chữ và số!"
            )
            .isValidSync(value); // Kiểm tra xem mật khẩu có đúng điều kiện không
        }
        // Nếu 'a' không tồn tại, mật khẩu không bắt buộc
        return yup
          .string()
          .notRequired()
          .matches(
            /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/,
            "Mật khẩu chứa ít nhất 8 kí tự, bao gồm chữ và số!"
          )
          .isValidSync(value); // Kiểm tra xem mật khẩu có đúng điều kiện không
      }
    ),
  });

  useEffect(() => {
    if (idUpdate) {
      form.setFieldsValue(record);
      form.setFieldValue("dataEntryClerk", record?.dataEntryClerk);
      form.setFieldValue("techSupporter", record?.techSupporter);

      form.setFieldValue("password", record?.personalConfig?.password);
    } else {
      form.resetFields();
    }
  }, [record, open]);

  const yupSync = [getYupSync(formSchema)];

  function handleSubmit() {
    const values = form.getFieldsValue([
      "status",
      "phone",
      "name",
      "password",
      "nameForPig",
      "dataEntryClerk",
      "herdTypeIds",
      "servicePackId",
      "email",
      "farmScaleTTNId",
      "farmScaleTTTId",
      "address",
      "province",
      "district",
      "techSupporter",
      "barnType",
    ]);

    if (!idUpdate) {
      handleCreateUser(values);
      return;
    }
    handleUpdateUser(values);
  }

  function handleCreateUser(values: any) {
    setLoading(true);
    const province = handleConvertProvinceIdToName(
      _.get(values, "province", "")
    );
    const payload: PayloadType = {
      body: {
        personalConfig: {
          nameForPig: _.get(values, "nameForPig", undefined),
          herdTypeIds: _.get(values, "herdTypeIds", undefined),
          farmScaleTTTId: _.get(values, "farmScaleTTTId", undefined),
          farmScaleTTNId: _.get(values, "farmScaleTTNId", undefined),
          province: _.get(province, "province_name", undefined),
          district: _.get(values, "district", undefined),
          barnType: _.get(values, "barnType", undefined),
        },
        password: _.get(values, "password", undefined),
        status: _.get(values, "status", undefined),
        email: _.get(values, "email", undefined),
        name: _.get(values, "name", undefined),
        address: _.get(values, "address", undefined),
        phone: _.get(values, "phone", undefined),
        techSupporter: _.get(values, "techSupporter", undefined),
        dataEntryClerk: _.get(values, "dataEntryClerk", undefined),
      },
      callback: {
        success(values) {
          notification.success({
            message: "Tạo mới người dùng thành công!",
          });
          handleGetUser();
          closeModal(dispatch, setModal);
          setLoading(false);
        },
        failed(errorMessage) {
          notification.error({
            message: "Không thể  tạo mới người dùng!",
            description: errorMessage,
          });
          setLoading(false);
        },
      },
    };
    dispatch(createSubscriber(payload));
  }
  function handleUpdateUser(values: any) {
    setLoading(true);
    const payload: PayloadType = {
      callback: {
        success(roles) {
          const results = _.get(roles, "results", null);
          if (!!results && _.isArray(results) && results.length > 0) {
            const roleUser: Role | null = results.find(
              (item: Role) => item.name === "user"
            );
            if (roleUser) {
              const payload: PayloadType = {
                params: idUpdate,
                body: {
                  status: _.get(values, "status", undefined),
                  email: _.get(values, "email", undefined),
                  password: _.get(values, "password", undefined),
                  name: _.get(values, "name", undefined),
                  address: _.get(values, "address", undefined),
                  phone: _.get(values, "phone", undefined),
                  techSupporter: _.get(values, "techSupporter", undefined),
                  dataEntryClerk: _.get(values, "dataEntryClerk", undefined),
                },
                callback: {
                  success(values) {
                    notification.success({
                      message: "Cập nhật thông tin người dùng thành công!",
                    });
                    handleGetUser();
                    closeModal(dispatch, setModal);
                    setLoading(false);
                  },
                  failed(errorMessage) {
                    notification.error({
                      message: "Không thể cập nhật thông tin người dùng!",
                      description: errorMessage,
                    });
                    setLoading(false);
                  },
                },
              };
              dispatch(updateSubscriber(payload));
            }
          }
        },
        failed(errorMessage) {
          notification.error({
            message: "Không thể cập nhật thông tin người dùng!",
            description: "Thiếu quyền!",
          });
          setLoading(false);
        },
      },
    };
    dispatch(getRoles(payload));
  }

  // set initalValue cho form Create và UPdate
  const initValues = idUpdate
    ? {
        record,
      }
    : {
        status: "active",
        phone: "",
        name: "",
        password: "",
        dataEntryClerk: undefined,
        nameForPig: "Heo",
        herdTypeIds: undefined,
        servicePackId: undefined,
        farmScaleTTNId: undefined,
        farmScaleTTTId: undefined,
        email: "",
        techSupporter: undefined,
        address: "",
        province: undefined,
        district: undefined,
        barnType: undefined,
      };

  return (
    <Form
      form={form}
      labelCol={{ span: 8 }}
      onFinish={() => {
        handleSubmit();
      }}
      initialValues={initValues}
      autoComplete="off"
    >
      <div className="ant-modal-body">
        <Row gutter={16}>
          <Col className="gutter-row" span={idUpdate ? 24 : 12}>
            <h4 style={{ marginBottom: 20 }}>Thông tin tài khoản</h4>
            <Form.Item
              required
              label={"Tên đăng nhập"}
              name={"phone"}
              rules={yupSync}
            >
              <Input
                disabled={!!idUpdate}
                placeholder="Nhập vào số điện thoại"
              />
            </Form.Item>

            <Form.Item
              required={idUpdate ? false : true}
              label={"Mật khẩu"}
              name={"password"}
              rules={yupSync}
            >
              <Input
                type="password"
                placeholder={
                  idUpdate
                    ? "Nếu không muốn thay đổi mật khẩu.Vui lòng bỏ qua trường này !"
                    : "Vui lòng nhập mật khẩu"
                }
              />
            </Form.Item>

            <Form.Item
              required
              label={"Họ tên chủ trại"}
              name={"name"}
              rules={yupSync}
            >
              <Input placeholder="Nhập tên chủ trại" />
            </Form.Item>

            <Form.Item label={"Email"} name={"email"} rules={yupSync}>
              <Input placeholder="Nhập vào email" />
            </Form.Item>
            <Form.Item label={"Kỹ thuật hỗ trợ"} name={"techSupporter"}>
              <Select
                showSearch
                allowClear
                style={{ width: "100%" }}
                placeholder="Chọn kỹ thuật hỗ trợ"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={listAdmin.map((item: SelectOptionType) => {
                  return {
                    label: _.get(item, "label", ""),
                    value: item.value,
                  };
                })}
              />
            </Form.Item>
            <Form.Item label={"Nhân viên nhập liệu"} name={"dataEntryClerk"}>
              <Select
                showSearch
                allowClear
                style={{ width: "100%" }}
                placeholder="Chọn nhân viên nhập liệu"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={listAdmin.map((item: SelectOptionType) => {
                  return {
                    label: _.get(item, "label", ""),
                    value: item.value,
                  };
                })}
              />
            </Form.Item>

            <Form.Item label={"Địa chỉ"} name={"address"}>
              <TextArea rows={3} placeholder="Nhập vào địa chỉ" />
            </Form.Item>
          </Col>
          {!idUpdate && (
            <Col className="gutter-row" span={12}>
              <h4 style={{ marginBottom: 20 }}>Thông tin trại</h4>
              <Form.Item label={"Phân loại trại"} name={"herdTypeIds"}>
                <Select
                  showSearch
                  allowClear
                  mode="multiple"
                  style={{ width: "100%" }}
                  placeholder="Chọn phân loại trại"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "").includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={listFarmType}
                />
              </Form.Item>

              <Form.Item label={"Quy mô tổng số nái"} name={"farmScaleTTNId"}>
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Chọn quy mô"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "").includes(input)
                  }
                  allowClear
                  options={listFarmScale}
                />
              </Form.Item>
              <Form.Item label={"Quy mô tổng số thịt"} name={"farmScaleTTTId"}>
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Chọn quy mô"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "").includes(input)
                  }
                  allowClear
                  options={listFarmScale}
                />
              </Form.Item>

              <Form.Item label={"Loại chuồng"} name={"barnType"}>
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Chọn loại chuồng trại"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "").includes(input)
                  }
                  allowClear
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={LOAI_CHUONG}
                />
              </Form.Item>
              <Form.Item label={"Gói dịch vụ"} name={"servicePackId"}>
                <Select
                  showSearch
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Chọn gói dịch vụ"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "").includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={servicePack.map((item: SelectOptionType) => {
                    return {
                      label: _.get(item, "label", ""),
                      value: item.value,
                    };
                  })}
                />
              </Form.Item>

              <Form.Item label={"Tỉnh"} name={"province"}>
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Chọn tỉnh"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "").includes(input)
                  }
                  onChange={(value: any) => {
                    handleChangeProvince(value);
                  }}
                  allowClear
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={optionProinces}
                />
              </Form.Item>

              <Form.Item label={"Quận/Huyện"} name={"district"}>
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Chọn quận/huyện"
                  optionFilterProp="children"
                  allowClear
                  disabled={districtList.length === 0 ? true : false}
                  filterOption={(input, option) =>
                    (option?.label ?? "").includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={districtList}
                />
              </Form.Item>

              <Form.Item label={"Thiết lập tên gọi"} name="nameForPig">
                <Radio.Group>
                  <Radio value={"Heo"}>Heo</Radio>
                  <Radio value={"Lợn"}>Lợn</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          )}
        </Row>
      </div>
      <div className="ant-modal-footer">
        <Button
          type="default"
          onClick={() => {
            closeModal(dispatch, setModal);
          }}
        >
          Huỷ
        </Button>
        <Button
          onClick={() => {
            if (!loading) form.submit();
          }}
          type="primary"
          loading={loading}
        >
          {idUpdate ? "Xác nhận" : "Thêm mới"}
        </Button>
      </div>
    </Form>
  );
}

export default CUUser;
