/**
 * Tab Nhóm khác
 */
import { useEffect } from "react";
import moment from "moment";
import {
  Button,
  Popconfirm,
  Table,
  Space,
  Typography,
  notification,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import { useHistory } from "react-router";
import { ModalInfoType, PayloadType } from "type";
import { useDispatch } from "react-redux";
import { setModal } from "features/modalSlice";
import { useSelector } from "react-redux";
import CUPromotionDetail from "pages/PromotionDetail/ListPromotion/CUPromotionDetail";
import _ from "lodash";
import { getPromotions, setQuery } from "features/promotionsSlice";
import queryString from "query-string";
import CUPromotion from "./CUPromotion";
const { Title } = Typography;

function ListPromotion() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { query, promotions } = useSelector(
    (state: any) => state.promotionReducer
  );

  //Lấy danh sách tài khoản người dùng
  function handleGetPromotions() {
    const payload: PayloadType = {
      query: queryString.stringify(
        {
          ...query,
        },
        {
          skipNull: true,
          skipEmptyString: true,
        }
      ),
      skipUpdateReducer: false,
      callback: {
        success(values) {},
        failed(errorMessage) {
          notification.error({
            message: "Có lỗi xảy ra trong quá trình lấy danh sách!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getPromotions(payload));
  }
  useEffect(() => {
    handleGetPromotions();
  }, [query]);

  const columns: ColumnsType<any> = [
    {
      title: "Tên chương trình KM",
      dataIndex: "name",
      key: "name",
      width: 500,
    },
    {
      title: "Hình thức",
      dataIndex: "form",
      key: "form",
      width: 300,
      render: (val, record) => {
        return <>{val === "money" ? "Giảm trực tiếp" : "Theo phần trăm"}</>;
      },
    },
    {
      title: "Giá trị",
      dataIndex: "value",
      key: "value",
      width: 150,
      render: (value, record) => {
        console.log("sdfsfsdfgsd", record);
        return (
          <div style={{ textAlign: "right" }}>
            {`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}{" "}
            {record?.form === "percent" ? "%" : "vnđ"}
          </div>
        );
      },
    },
    {
      title: "Thời gian bắt đầu",
      dataIndex: "startDate",
      key: "startDate",
      width: 200,
      render: (val, record) => {
        return (
          <div style={{ textAlign: "center" }}>
            {moment(val).utc().format("DD/MM/YYYY")}
          </div>
        );
      },
      align: "center",
    },

    {
      title: "Thời gian kết thúc",
      dataIndex: "endDate",
      key: "endDate",
      width: 300,
      render: (val, record) => {
        return (
          <div style={{ textAlign: "center" }}>
            {moment(val).utc().format("DD/MM/YYYY")}
          </div>
        );
      },
      align: "center",
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      width: 200,
      render: (val, record) => {
        return <>{!(val === "active") ? "Đóng" : "Hoạt động"}</>;
      },
      align: "center",
    },

    {
      title: "Hành động",
      dataIndex: "action",
      key: "action",
      align: "left",
      width: 300,
      render: (_, record: any) => {
        return (
          <Space size="middle">
            <a
              onClick={() => {
                const payload: ModalInfoType = {
                  open: true,
                  title: "Thêm mới chi tiết khuyến mãi",
                  content: <CUPromotionDetail record={record} />,
                  size: 600,
                };
                dispatch(setModal(payload));
              }}
            >
              Thêm CTKM
            </a>
            <a
              onClick={() => {
                history.push({
                  pathname: "/admin/promotion-info/" + record?.id,
                  state: record,
                });
              }}
            >
              Xem
            </a>
            <a
              onClick={() => {
                record = {
                  ...record,
                };

                const payload: ModalInfoType = {
                  open: true,
                  title: "Sửa chương trình khuyến mãi",
                  content: (
                    <CUPromotion
                      idUpdate={record?.id ?? false}
                      record={record}
                    />
                  ),
                  size: 600,
                };
                dispatch(setModal(payload));
              }}
            >
              Sửa
            </a>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <div
        className="space-between"
        style={{
          marginTop: 12,
          marginBottom: 24,
        }}
      >
        <Title level={5}>Danh sách</Title>
        <Space className="custom-placeholder-select">
          <Button
            type="primary"
            onClick={() => {
              const payload: ModalInfoType = {
                open: true,
                title: "Thêm mới chương trình khuyến mãi",
                content: <CUPromotion idUpdate={undefined} />,
                size: 600,
              };
              dispatch(setModal(payload));
            }}
          >
            <PlusOutlined />
            Thêm mới
          </Button>
        </Space>
      </div>
      <Table
        columns={columns}
        rowKey={"id"}
        dataSource={_.get(promotions, "results", [])}
        rowClassName={(record: any) =>
          record?.status === "active" ? "table-row-open" : "table-row-close"
        }
        pagination={{
          total: _.get(promotions, "totalResults", []),
          current: _.get(query, "page", 1),
          pageSize: _.get(query, "limit", 10),
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50", "100"],
          showTotal: (total, range) => {
            return `Có tất cả ${total} bản ghi`;
          },
          onChange(page, pageSize) {
            if (_.get(query, "limit", 0) !== pageSize) {
              dispatch(
                setQuery({
                  ...query,
                  page: 1,
                  limit: pageSize,
                })
              );
              return;
            }
            dispatch(
              setQuery({
                ...query,
                page,
                limit: pageSize,
              })
            );
          },
        }}
        scroll={{ x: 1300 }}
      />
    </>
  );
}

export default ListPromotion;
