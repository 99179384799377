/**
 * Màn hình hiển thị danh sách tài khoản người dùng
 */
import { useState, useEffect } from "react";

import {
  Button,
  Popconfirm,
  Table,
  Typography,
  notification,
  Input,
  Space,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
const { Search } = Input;
import { ModalInfoType, PayloadType, DataTypeSubscriber } from "type";
import { styled } from "styled-components";
import { useDispatch } from "react-redux";
import { setModal } from "features/modalSlice";
import CUUser from "pages/User/ListUser/CUUser";
import RUser from "pages/User/ListUser/RUser";
import { useSelector } from "react-redux";
import _ from "lodash";
import {
  getSubscribers,
  setQuery,
  updateSubscriber,
} from "features/subscriberSlice";
import queryString from "query-string";
import moment from "moment";
const { Title } = Typography;

const CustomPopconfirm = styled(Popconfirm)`
  .ant-popover-inner {
    max-width: 240px !important;
    background-color: red;
  }
`;
function ListUser() {
  const dispatch = useDispatch();
  const { query, subscribers } = useSelector(
    (state: any) => state.subscriberReducer
  );
  const [searchText, setSearchText] = useState<string>("");

  //Lấy danh sách tài khoản người dùng
  function handleGetUsers() {
    const payload: PayloadType = {
      query: queryString.stringify(
        {
          ...query,
          populate: "personInChargeId, personalConfig. farmScaleId  farmTypeId",
          sortBy: "createdAt:desc",
        },
        {
          skipNull: true,
          skipEmptyString: true,
        }
      ),
      skipUpdateReducer: false,
      callback: {
        success(values) {},
        failed(errorMessage) {
          notification.error({
            message: "Có lỗi xảy ra trong quá trình lấy danh sách!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getSubscribers(payload));
  }
  useEffect(() => {
    handleGetUsers();
  }, [query]);

  const handleSearch = () => {
    console.log("query", query);
    dispatch(
      setQuery({
        ...query, // lấy lại những thuộc tính về query ban đầu được lấy từ useReducer
        phone: searchText,
        page: 1, //Trường lọc data là name và
      })
    );
  };

  const columns: ColumnsType<DataTypeSubscriber> = [
    {
      title: "Tên đăng nhập",
      dataIndex: "phone",
      key: "phone",
      width: 150,
    },
    {
      title: "Họ tên chủ trang trại",
      dataIndex: "name",
      key: "name",
      width: 250,
    },
    {
      title: "Ngày đăng ký",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 150,
      render: (val, record) => {
        return (
          <div style={{ textAlign: "center" }}>
            {moment(val).utc().format("DD/MM/YYYY")}
          </div>
        );
      },
    },
    {
      title: "Kỹ thuật hỗ trợ",
      dataIndex: "email",
      key: "email",
      width: 100,
    },
    {
      title: "Nhân viên nhập liệu",
      dataIndex: "email",
      key: "email",
      width: 100,
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (val, record) => {
        return <>{!(val === "active") ? "Đóng" : "Hoạt động"}</>;
      },
      width: 200,
    },
    {
      title: "Hành động",
      dataIndex: "action",
      key: "action",
      align: "left",
      width: 220,
      render: (_, record: any) => {
        return (
          <Space size="middle">
            <a
              onClick={() => {
                const payload: ModalInfoType = {
                  open: true,
                  title: "Xem thông tin tài khoản người dùng",
                  content: <RUser idUpdate={record?.id} record={record} />,
                  size: 1200,
                };
                dispatch(setModal(payload));
              }}
            >
              Xem
            </a>
            <a
              onClick={() => {
                record = {
                  ...record,
                };

                const payload: ModalInfoType = {
                  open: true,
                  title: "Sửa tài khoản người dùng",
                  content: (
                    <CUUser idUpdate={record?.id ?? false} record={record} />
                  ),
                  size: 700,
                };
                dispatch(setModal(payload));
              }}
            >
              Sửa
            </a>
            <CustomPopconfirm
              title={`Bạn có chắc chắn muốn ${
                record?.status === "active" ? "khóa" : "mở khóa"
              } tài khoản ${record?.name ?? ""}  không ?`}
              okText={"Có"}
              cancelText={"Không"}
              onConfirm={() => {
                const id = record?.id;
                if (id) {
                  const payload: PayloadType = {
                    params: id,
                    body: {
                      email: record?.email,
                      name: record?.name,
                      address: record?.address,
                      phone: record?.phone,
                      personInChargeId: record?.personInChargeId?.id,
                      status: record?.status === "active" ? "lock" : "active",
                    },
                    callback: {
                      success: () => {
                        notification.success({
                          message:
                            record?.status === "active"
                              ? "Khóa tài khoản người dùng thành công!"
                              : "Mở khóa tài khoản người dùng thành công!",
                        });
                        handleGetUsers();
                      },
                      failed(errorMessage) {
                        notification.error({
                          message: `${
                            record?.status === "active" ? "Khóa" : "Mở khóa"
                          } tài khoản người dùng thành công!`,
                          description: errorMessage,
                        });
                      },
                    },
                  };
                  dispatch(updateSubscriber(payload));
                }
              }}
            >
              <a>{record?.status === "active" ? "Đóng" : "Mở"}</a>
            </CustomPopconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <div
        className="space-between"
        style={{
          marginTop: 12,
          marginBottom: 24,
        }}
      >
        <Title level={5}>Danh sách</Title>
        <Space className="custom-placeholder-select">
          <Search
            placeholder="Tìm kiếm theo tên đăng nhập "
            onChange={(e) => setSearchText(e.target.value)}
            onSearch={handleSearch}
            style={{ width: 242 }}
          />
          <Button
            type="primary"
            onClick={() => {
              const payload: ModalInfoType = {
                open: true,
                title: "Thêm mới tài khoản người dùng",
                content: <CUUser idUpdate={undefined} />,
                size: 1031,
              };
              dispatch(setModal(payload));
            }}
          >
            <PlusOutlined />
            Thêm mới
          </Button>
        </Space>
      </div>
      <Table
        columns={columns}
        rowKey={"id"}
        dataSource={_.get(subscribers, "results", [])}
        rowClassName={(record: any) =>
          record?.status === "active" ? "table-row-open" : "table-row-close"
        }
        pagination={{
          total: _.get(subscribers, "totalResults", []),
          current: _.get(query, "page", 1),
          pageSize: _.get(query, "limit", 10),
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50", "100"],
          showTotal: (total, range) => {
            return `Có tất cả ${total} bản ghi`;
          },
          onChange(page, pageSize) {
            if (_.get(query, "limit", 0) !== pageSize) {
              dispatch(
                setQuery({
                  ...query,
                  page: 1,
                  limit: pageSize,
                })
              );
              return;
            }
            dispatch(
              setQuery({
                ...query,
                page,
                limit: pageSize,
              })
            );
          },
        }}
        scroll={{ x: 1300 }}
      />
    </>
  );
}

export default ListUser;
